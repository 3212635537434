import GatsbyImage, { FixedObject, FluidObject } from "gatsby-image";
import React from "react";

interface GatsbyImageProps {
    resolutions?: FixedObject;
    sizes?: FluidObject;
    fixed?: FixedObject | FixedObject[];
    fluid?: FluidObject | FluidObject[];
    fadeIn?: boolean;
    durationFadeIn?: number;
    title?: string;
    alt?: string;
    className?: string | object;
    critical?: boolean;
    crossOrigin?: string | boolean;
    style?: object;
    imgStyle?: object;
    placeholderStyle?: object;
    placeholderClassName?: string;
    backgroundColor?: string | boolean;
    onLoad?: () => void;
    onError?: (event: any) => void;
    onStartLoad?: (param: { wasCached: boolean }) => void;
    Tag?: string;
    itemProp?: string;
    loading?: `auto` | `lazy` | `eager`;
    draggable?: boolean;
}

type Props = {
    fluid?: FluidObject;
    fixed?: FixedObject;
} & GatsbyImageProps;

const SquareImage = ({ fluid, fixed, className = "", ...props }: Props) => {
    const image = fluid
        ? { fluid: { ...fluid, aspectRatio: 1 } }
        : { fixed: { ...fixed, aspectRatio: 1 } };

    return (
        <GatsbyImage
            className={`rounded shadow-2xl ${className}`}
            {...image}
            {...props}
        />
    );
};

export default SquareImage;
