import pageColors from "../../data/pageColors";
import SquareImage from "./SquareImage";
import React from "react";
import { EventsJson } from "../../index";
import { TIMELINE_BREAKPOINT } from "./Timeline";

const Event = ({
    event,
    className = "",
}: {
    event: EventsJson;
    className?: string;
}) => (
    <article
        className={`border-t-0 border-b-0 border-solid border-${pageColors.about}-700 p-5 mb-0 ${className}`}
    >
        <h2 className={"text-3xl font-hairline m-0 mb-3"}>
            <span
                className={`text-${pageColors.about}-600 dark:text-${pageColors.about}-500`}
            >
                {event.year}
            </span>
            {event.duration > 0 && (
                <span className="text-gray-600 block">
                    {event.year + event.duration}
                </span>
            )}
        </h2>
        <SquareImage
            fluid={event.image.childImageSharp.fluid}
            className={`w-1/2 mb-3 ${TIMELINE_BREAKPOINT}:w-full`}
        />
        <p className={"text-gray-600 dark:text-gray-300"}>{event.event}</p>
    </article>
);

export default Event;
