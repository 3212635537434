import React from "react";
import Page from "../components/Layout/Page";
import { graphql } from "gatsby";
import ExternalLink from "../components/Elements/ExternalLink";
import pageTitles from "../data/pageTitles";
import pageColors from "../data/pageColors";
import SquareImage from "../components/Elements/SquareImage";
import { EventsJsonConnection, File, Query } from "../index";
import SocialLinks from "../components/Elements/SocialLinks";
import Timeline from "../components/Elements/Timeline";

const unsplashCredits = {
    "Artem Maltsev": "u1MWxPSkUAA",
    "Clément Falize": "hmxJGMq9BAQ",
    "Edu Lauton": "5oyFrBF33Q4",
    "Filip Zrnzević": "QsWG0kjPQRY",
    "Katie Moum": "5FHv5nS7yGg",
    "M N": "HCT6rZIy6Ks",
    "Marvin Ronsdorf": "sX9_SHIqH4w",
    "Michael LaRosa": "kR1Aer8c_WI",
    "Nathan Wright": "igpwuxZofgo",
    "Nikita Tikhomirov": "dv7cSiHurKM",
    "Reza Hasannia": "W1J8mMlkmXY",
    "Steve Halama": "oC1sQVnf_EY",
    "The Ian": "j7uMGYBb4jY",
    "Tim Mossholder": "ASUS209zsJ8",
    "Van Mendoza": "r7YZXv5f5cc",
    vaun0815: "w-kZTT2GtHM",
};

const Link = ({ children, href, ...props }) => (
    <ExternalLink
        {...props}
        href={href}
        className={`text-${pageColors.about}-700 dark:text-${pageColors.about}-500 font-bold`}
    >
        {children}
    </ExternalLink>
);

export const Heading = ({ children, className = "" }) => (
    <h2
        className={`text-xl font-bold bg-${pageColors.about}-600 text-white p-2 px-3 text-shadow rounded mb-5 mt-20 ${className}`}
    >
        {children}
    </h2>
);

const About = ({
    data,
}: {
    data: Query & {
        me: File;
        portfolio2011: File;
        portfolio2014: File;
        workEvents: EventsJsonConnection;
        lifeEvents: EventsJsonConnection;
    };
}) => (
    <Page
        title={pageTitles.about}
        layoutClassName={"text-gray-700 dark:bg-gray-800 dark:text-white"}
        className={"font-sans"}
    >
        <div className="flex flex-col xl:flex-row justify-center p-5 xl:p-20">
            <div className={"flex-1 text-left xl:text-right xl:mr-10"}>
                <SocialLinks socialLinks={data.allSocialLinksJson.nodes} />
                <div className={"text-lg"}>
                    <p>
                        My name is <strong>Emma Fabre</strong>, I'm{" "}
                        {new Date().getFullYear() - 1990} years old and I live
                        in France. I've always been someone with a passion for
                        creating things: I started writing very early, then
                        drawing, and so on. I went to an "applied arts" school
                        which taught me how to communicate visually and forced
                        me to expand my horizons and try a variety of medium.
                    </p>
                    <p className={"my-10"}>
                        Along the way I learned the ways of the web by myself on{" "}
                        <Link href={"http://openclassrooms.com/"}>
                            OpenClassrooms
                        </Link>{" "}
                        (called <em>Le Site du Zéro</em> back then), but never
                        did I imagine it would become my career and passion, it
                        was just a mean to an end to showcase some of the things
                        I had made. But when I started working in advertising
                        and had the chance to put those skills into practice
                        more and more, only then did I realize{" "}
                        <strong>this is what I wanted to do</strong>. I was
                        fascinated by the web, the open source community, and
                        all the absolutely crazy things we could already do with
                        the technologies of that time.
                    </p>
                    <p className="my-10">
                        Eventually through sheer luck I landed at{" "}
                        <Link href={"madewithlove.be"}>madewithlove</Link>,
                        where I found people whose vision of the web, of being a
                        developper, aligned so perfectly with my own that I've
                        been with these amazing people ever since and I've kept
                        growing there more than ever before.
                    </p>
                    <Heading>About this website</Heading>
                    <p>
                        This website was made with{" "}
                        <Link href={"https://www.gatsbyjs.org"}>Gatsby</Link>{" "}
                        and{" "}
                        <Link href={"https://www.typescriptlang.org/"}>
                            Typescript
                        </Link>
                        , it is <strong>entirely static</strong> and rebuilt
                        when new content is pushed, which is why it's{" "}
                        <em>so fast</em>. All data (photos, tracks, etc.) are
                        centralized in a GraphQL API that all pages then query.
                    </p>
                    <p className={"mt-10 mb-5 text-center"}>
                        Here is what this website used to look like just for
                        posterity.
                    </p>
                    <div className="flex flex-col sm:flex-row">
                        {[data.portfolio2011, data.portfolio2014].map(
                            (image) => (
                                <figure
                                    key={image.name}
                                    className={
                                        "flex-1 text-center font-bold p-3"
                                    }
                                >
                                    <ExternalLink
                                        href={
                                            image.childImageSharp.original.src
                                        }
                                    >
                                        <SquareImage
                                            fluid={image.childImageSharp.fluid}
                                        />
                                        <figcaption className={"mt-5"}>
                                            {image.name}
                                        </figcaption>
                                    </ExternalLink>
                                </figure>
                            ),
                        )}
                    </div>
                    <Heading>Credits</Heading>
                    <div>
                        The images used for my short stories come from{" "}
                        <Link href={"https://unsplash.com/"}>Unsplash</Link>,
                        here are the credits for them.
                        <ul className={"my-5 column-3"}>
                            {Object.keys(unsplashCredits).map((author) => (
                                <li key={author}>
                                    <ExternalLink
                                        className={`text-gray-500 hover:text-${pageColors.about}-700 transition`}
                                        href={`https://unsplash.com/photos/${unsplashCredits[author]}`}
                                    >
                                        {author}
                                    </ExternalLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <Timeline
                events={data.allEventsJson}
                workEvents={data.workEvents}
                lifeEvents={data.lifeEvents}
            />
        </div>
    </Page>
);

export const query = graphql`
    fragment EventWithImage on EventsJson {
        id
        event
        year
        duration
        color
        type
        image {
            childImageSharp {
                fluid(quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
    query {
        portfolio2011: file(relativePath: { eq: "2009 - 2012.png" }) {
            name
            childImageSharp {
                fluid(quality: 90) {
                    ...GatsbyImageSharpFluid
                }
                original {
                    src
                }
            }
        }
        portfolio2014: file(relativePath: { eq: "2012 - 2018.jpg" }) {
            name
            childImageSharp {
                fluid(quality: 90) {
                    ...GatsbyImageSharpFluid
                }
                original {
                    src
                }
            }
        }
        me: file(relativePath: { eq: "aboutme.jpg" }) {
            childImageSharp {
                fluid(quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        allEventsJson(sort: { fields: year }) {
            nodes {
                ...EventWithImage
            }
        }
        workEvents: allEventsJson(
            filter: { type: { eq: "work" } }
            sort: { fields: year }
        ) {
            nodes {
                ...EventWithImage
            }
        }
        lifeEvents: allEventsJson(
            filter: { type: { eq: "life" } }
            sort: { fields: year }
        ) {
            nodes {
                ...EventWithImage
            }
        }
        allSocialLinksJson {
            nodes {
                name
                link
                color
            }
        }
    }
`;

export default About;
