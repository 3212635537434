import ExternalLink from "./ExternalLink";
import React from "react";

const Youtube = require("../../images/social/youtube.svg");
const Twitter = require("../../images/social/twitter.svg");
const Lastfm = require("../../images/social/lastfm.svg");
const Linkedin = require("../../images/social/linkedin.svg");
const Github = require("../../images/social/github.svg");
const Flickr = require("../../images/social/flickr.svg");
const Facebook = require("../../images/social/facebook.svg");
const Steam = require("../../images/social/steam.svg");
const Soundcloud = require("../../images/social/soundcloud.svg");

const icons = {
    youtube: Youtube,
    twitter: Twitter,
    "last.fm": Lastfm,
    linkedin: Linkedin,
    github: Github,
    flickr: Flickr,
    facebook: Facebook,
    steam: Steam,
    soundcloud: Soundcloud,
};

const SocialLinks = ({ socialLinks }) => (
    <nav
        className={"grid grid-flow-col grid-rows-2 gap-x-4"}
        style={{ position: "relative", bottom: 5 }}
    >
        {socialLinks.map((socialLink) => {
            const Icon = icons[socialLink.name.toLowerCase()];

            return (
                <ExternalLink
                    key={socialLink.link}
                    href={socialLink.link}
                    style={{
                        backgroundColor: socialLink.color,
                        height: "2rem",
                    }}
                    className={
                        "text-white rounded mb-3 mr-0 shadow-xl p-1 px-2 shadow-inner transition lowercase hover:opacity-50 flex justify-between hover:font-bold"
                    }
                >
                    <Icon
                        className={"fill-current text-white mr-2"}
                        style={{ width: 20 }}
                    />
                    {socialLink.name}
                </ExternalLink>
            );
        })}
    </nav>
);

export default SocialLinks;
