import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const ExternalLink = ({ href, children, ...props }) => (
    <OutboundLink
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
    >
        {children}
    </OutboundLink>
);

export default ExternalLink;
