import { EventsJsonConnection } from "../../index";
import React from "react";
import Event from "./Event";
import { Heading } from "../../pages/about";

export const TIMELINE_BREAKPOINT = "sm";

const Timeline = ({
    events,
    workEvents,
    lifeEvents,
}: {
    events: EventsJsonConnection;
    workEvents: EventsJsonConnection;
    lifeEvents: EventsJsonConnection;
}) => (
    <>
        <div
            className={`hidden text-left flex-1 flex-row ${TIMELINE_BREAKPOINT}:flex`}
        >
            <div className="flex-1 flex flex-col">
                {workEvents.nodes.map((event) => (
                    <Event
                        key={event.id}
                        event={event}
                        className={"flex-1 border-r-8 border-l-0 text-right"}
                    />
                ))}
            </div>
            <div className="flex-1">
                {lifeEvents.nodes.map((event) => (
                    <Event
                        key={event.id}
                        event={event}
                        className={"border-l-8 border-r-0 text-left"}
                    />
                ))}
            </div>
        </div>
        <div
            className={`${TIMELINE_BREAKPOINT}:hidden text-left flex-1 flex-col flex`}
        >
            <Heading>Timeline</Heading>
            {events.nodes.map((event) => (
                <Event
                    key={event.id}
                    event={event}
                    className={"border-l-8 border-r-0 text-left"}
                />
            ))}
        </div>
    </>
);

export default Timeline;
